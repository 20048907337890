.NotFoundContainer{
    width: '100%';
    height: 100vh;
    flex: 1;
    justify-content: center;
    align-items: center;
}
.NotFoundFlexContainer{
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    flex: 1;
}
.LeftContainer{
    flex: 1;
    background-color: white;
    width: 100%;
    height: 400px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.RightContainer{
    flex: 1;
    background-color: white;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.GoHome{
    background-color: #ff5722;
    color: #fff;
    font-size: 0.875rem;
    font-weight: 500;
    border-color: #ff5722;
    padding: 1.6rem 2rem;
    letter-spacing: 1px;
}